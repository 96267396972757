import React from 'react'

import { defaultSettings } from './defaults/settings'
import Component from './Component'

const createPlugin = settings => {
    const mergedSettings = { ...defaultSettings, ...settings }

    return {
        Component: props => <Component {...props} {...mergedSettings} />,

        name: 'peracto/content/text',
        version: '1.0.0',
        text: mergedSettings.translations.pluginName,
        description: mergedSettings.translations.pluginDescription,
        IconComponent: mergedSettings.IconComponent,

        handleFocus: (props, source) => {
            if (source === 'onMouseDown') {
                return
            }

            setTimeout(() => props.onChange(props.state), 0)
        },

        handleBlur: props => {
            props.onChange(props.state)
        },

        createInitialState: () => ({
            text: mergedSettings.defaultText,
        }),
    }
}

export default createPlugin
