import createPlugin from './createPlugin'
import TextRenderer from './Renderer/TextRenderer'

const textPlugin = settings =>
    createPlugin({
        Renderer: TextRenderer,
        ...settings,
    })

const text = textPlugin()
export default text

export { textPlugin, TextRenderer }
