import React from "react";
import type { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

export type CardProps = HTMLAttributes<HTMLDivElement> & {
    shadow?: boolean;
};

export const Card = (props: CardProps) => {
    const { shadow = true, className, children } = props;

    return (
        <div
            className={twMerge(
                `overflow-hidden rounded-[16px] bg-white p-0 ${
                    shadow
                        ? "shadow-[0px_8px_16px_0px_rgba(48,49,51,0.1),0px_0px_1px_0px_rgba(48,49,51,0.05)]"
                        : ""
                }`,
                className
            )}
            data-testid="card"
        >
            {children}
        </div>
    );
};
