import {
    Accordion,
    Carousel,
} from "@peracto/peracto-blocks-tailwind";

import Container from './Container'
import Cards from "./Cards";
import Products from "./Products";
import Departments from "./Departments";
import Brands from "./Brands";

export default {
    plugins: {
        content: [Carousel, Accordion, Products, Cards, Departments, Brands],
        layout: [Container],
    },
};
