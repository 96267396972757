import React from "react";
import { Field } from "formik";
import {
    Select,
    Group,
    Input,
    DateInput,
    Checkbox,
    RichText,
} from "@peracto/peracto-ui";
import { LocationIBTConfig } from "./LocationIBTConfig";

export const LocationFormContent = ({ countries, days, modeEdit }) => {
    const getCountryDefaultValue = (field, options) => {
        const found = options.find((option) => {
            return option.label === field.value;
        });
        return found || { label: "", value: "" };
    };

    return (
        <React.Fragment>
            <Group name="Location" id="location">
                <Input
                    label="Store Name"
                    required
                    name="name"
                    testId="store-name"
                />

                <Input
                    label="Store Notice"
                    name="generalInformation"
                    testId="store-name"
                />

                <Select
                    placeholder="Select Status"
                    name="locationStatus"
                    testId="location-status"
                    required
                    options={[
                        { label: "Open", value: "open" },
                        { label: "Closed", value: "closed" },
                        { label: "Now Open", value: "now_open" },
                        { label: "Coming Soon", value: "coming_soon" },
                    ]}
                    label="Status"
                />

                <Input
                    name="ecpBranchId"
                    label="Babbage Branch Number"
                    required
                    testId="location-ecpBranchId"
                />

                <Select
                    placeholder="Select Location Page Status"
                    name="locationPageStatus"
                    testId="location-page-status"
                    options={[
                        { label: "Active", value: "active" },
                        { label: "Inactive", value: "inactive" },
                    ]}
                    label="Location Page Status"
                />

                <Checkbox
                    name="showOnStoreLocator"
                    testId="location-showOnStoreLocator"
                    label="Show on store locator?"
                />

                <Checkbox
                    name="straightToPrint"
                    testId="location-straightToPrint"
                    label="Treat all orders for this branch as 'Straight to Print' (overriding individual user permissions)"
                />
            </Group>

            <Group name="Address" id="address">
                <Input
                    label="Building Name"
                    name="buildingName"
                    testId="building-name"
                />

                <Input
                    label="Street Number"
                    name="streetNo"
                    testId="street-number"
                />

                <Input
                    name="line1"
                    label="Address 1"
                    required
                    testId="location-line1"
                />
                <Input name="line2" label="Address 2" testId="location-line2" />

                <Input
                    name="city"
                    label="Town / City"
                    required
                    testId="location-city"
                />
                <Input
                    name="county"
                    label="County / State"
                    required
                    testId="location-county"
                />
                <Input
                    name="postcode"
                    label="Postal Code"
                    required
                    testId="location-postcode"
                />
                <Field name="country">
                    {({ field }) => {
                        const options = [
                            {
                                label: "United Kingdom",
                                value: "GBR",
                            },
                            {
                                label: "Ireland",
                                value: "IRL",
                            },
                        ];
                        return countries && options ? (
                            <Select
                                defaultValue={getCountryDefaultValue(
                                    field,
                                    options
                                )}
                                name={field.name}
                                testId="location-country"
                                label="Country"
                                options={options}
                                placeholder="Select a country"
                                required
                            />
                        ) : (
                            <p className="help-text">Loading...</p>
                        );
                    }}
                </Field>

                <Input
                    name="latitude"
                    label="Latitude"
                    testId="location-latitude"
                    required
                />
                <Input
                    name="longitude"
                    label="Longitude"
                    testId="location-longitude"
                    required
                />
            </Group>
            <Group name="Contact information" id="contact-info">
                <Input
                    name="telephone"
                    label="Telephone Number"
                    testId="location-telephone"
                    required
                />
                <Input name="email" label="Email" testId="location-email" />
            </Group>
            <Group name="Opening hours" id="opening-hours">
                {days.map((day) => {
                    const lowerCaseDay = day.toLowerCase();
                    return (
                        <Input
                            key={day}
                            name={`${lowerCaseDay}Hours`}
                            label={`Opening Times (${day})`}
                            testId={`location-openingTimes${day}`}
                        />
                    );
                })}

                <RichText
                    label="Opening Times (Bank Holidays)"
                    name="specialOpeningHours"
                    testId="location-specialOpeningHours"
                />

                <Input
                    name="seasonalOpeningLabel"
                    label="Seasonal Opening Label"
                    testId="location-seasonalOpeningLabel"
                />

                <RichText
                    label="Seasonal Opening Times"
                    name="seasonalOpeningTimes"
                    testId="location-seasonalOpeningTimes"
                />

                <DateInput
                    name="seasonalOpeningDisplayStart"
                    label="Seasonal Opening Display Start"
                    testId="location-seasonalOpeningDisplayStart"
                    fullWidth={true}
                    allowClear={true}
                />

                <DateInput
                    name="seasonalOpeningDisplayEnd"
                    label="Seasonal Opening Display End"
                    testId="location-seasonalOpeningDisplayEnd"
                    fullWidth={true}
                    allowClear={true}
                />

                <DateInput
                    name="openDate"
                    label="Store Open Date"
                    fullWidth={true}
                    allowClear={true}
                />

                <DateInput
                    name="closeDate"
                    label="Store Close Date"
                    fullWidth={true}
                    allowClear={true}
                />
            </Group>

            {modeEdit && (
                <Group
                    name="IBT Config Sequences"
                    id="ibt-configuration-sequences"
                >
                    <LocationIBTConfig />
                </Group>
            )}
        </React.Fragment>
    );
};
